


export const cognito = {
    REGION:"us-west-2",
    USER_POOL_ID:"us-west-2_SEjKq9xT8",
    APP_CLIENT_ID: "627mmef9pk8ftk85vsif7a1kct"
}

export const adminConstant ='https://oywe08w5nb.execute-api.us-west-2.amazonaws.com/tma_dev_admin/'
export const imageUrlConstant="https://rarecare-dev-user-profiles.s3.us-west-2.amazonaws.com/"
export const avatarConstant="/profileAvatar.jpg?"
export const RARECARE_API="https://dev-api.rarecare.cc/"