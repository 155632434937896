import { all } from 'redux-saga/effects';

import authSagas from './auth/saga';
import usersListSagas from './users/saga';
import casesListSagas from './case/saga';



export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        usersListSagas(),
        casesListSagas()
    ]);
}