import { NotifyMessages, reducerSuccessAndErrortexts } from '../../Constants/notify-messages';
import {
    CASE_LIST,
    CASE_LIST_SUCCESS,
    CASE_LIST_ERROR,
    CASE_DETAILS,
    CASE_DETAILS_SUCCESS,
    CASE_DETAILS_ERROR,
    UPDATE_CASE_DETAILS,
    UPDATE_CASE_DETAILS_SUCCESS,
    UPDATE_CASE_DETAILS_ERROR,
    ADD_CASE_CARD_DETAILS,
    ADD_CASE_CARD_DETAILS_SUCCESS,
    ADD_CASE_CARD_DETAILS_ERROR,
    UPDATE_SYMPTOM,
    UPDATE_SYMPTOM_SUCCESS,
    UPDATE_SYMPTOM_ERROR,
    DELETE_SYMPTOM,
    DELETE_SYMPTOM_SUCCESS,
    DELETE_SYMPTOM_ERROR,
    CHANGE_CASE_STATUS,
    CHANGE_CASE_STATUS_SUCCESS,
    CHANGE_CASE_STATUS_ERROR,
    CREATE_NEW_CASE,
    CREATE_NEW_CASE_SUCCESS,
    CREATE_NEW_CASE_ERROR,
    ASSIGN_CASE_MANAGER,
    ASSIGN_CASE_MANAGER_SUCCESS,
    ASSIGN_CASE_MANAGER_ERROR,
    REMOVE_CASE_MANAGER,
    REMOVE_CASE_MANAGER_SUCCESS,
    REMOVE_CASE_MANAGER_ERROR,
    GET_CASE_MANAGERS_LIST,
    GET_CASE_MANAGERS_LIST_SUCCESS,
    GET_CASE_MANAGERS_LIST_ERROR,
    DELETE_CASE,
    DELETE_CASE_SUCCESS,
    DELETE_CASE_ERROR,
    UPDATE_KEY_FINDINGS,
    UPDATE_KEY_FINDINGS_SUCCESS,
    UPDATE_KEY_FINDINGS_ERROR,
    GET_CASE_ANALYTICS,
    GET_CASE_ANALYTICS_SUCCESS,
    GET_CASE_ANALYTICS_ERROR,
    ADD_MALACARD_TO_CASE,
    ADD_MALACARD_TO_CASE_SUCCESS,
    ADD_MALACARD_TO_CASE_ERROR,
    REMOVE_MALACARD_FROM_CASE,
    REMOVE_MALACARD_FROM_CASE_SUCCESS,
    REMOVE_MALACARD_FROM_CASE_ERROR

} from '../Actions';

const INIT_STATE = {
    cases: [],
    loading: true,
    error: ''
};

const getItemIndex = (cases, TMA_number) => {
    return cases.findIndex(d => d.TMA_number === TMA_number)
}
const getCaseManagerIndex = (casemanagers, username) => {
    return casemanagers.findIndex(d => d.username === username)
}
const removeAnalyticsIndex = (analytics, McId) => {
    return analytics.findIndex(d => d.McId === McId)
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CASE_LIST:
            return { ...state, loading: true, caseListed: false, error: '' };
        case CASE_LIST_SUCCESS:
            return { ...state, loading: false, caseListed: true, caseListCount: action.payload.caseCount, cases: action.payload.casesList, error: '' };
        case CASE_LIST_ERROR:
            return { ...state, loading: false, caseListed: true, error: '' };

        case CASE_DETAILS:
            return { ...state, loading: true, newCaseCreated: '', caseDetailsUpdated: '', error: '' };
        case CASE_DETAILS_SUCCESS:
            return {
                ...state,
                diagnostoics: action.payload.diagnostoics,
                hospitalization: action.payload.hospitalization,
                imagings: action.payload.imagings,
                medications: action.payload.medications,
                surgeries: action.payload.surgeries,
                symptoms: action.payload.symptoms,
                freeform: action.payload.freeform,
                caseDetails: action.payload.caseDetails,
                case_managers: action.payload.case_managers,
                loading: false,
                error: ''
            };
        case CASE_DETAILS_ERROR:
            return { ...state, loading: false, caseDetails: '', error: action.payload.message };

        case UPDATE_CASE_DETAILS:
            return { ...state, loading: true, caseDetailsUpdated: '', caseDetailsUpdatedError: '', error: '' };
        case UPDATE_CASE_DETAILS_SUCCESS:
            return { ...state, loading: false, caseDetailsUpdated: reducerSuccessAndErrortexts.UPDATE_CASE_DETAILS_SUCCESS, caseDetailsUpdatedError: '', caseDetails: action.payload.case, error: '' };
        case UPDATE_CASE_DETAILS_ERROR:
            return { ...state, loading: false, caseDetailsUpdated: '', caseDetailsUpdatedError: reducerSuccessAndErrortexts.UPDATE_KEY_FINDINGS_ERROR, error: action.payload.message };

        case ADD_CASE_CARD_DETAILS:
            return { ...state, loading: true, addCaseCardDetails: '', addCaseError: '', error: '' };
        case ADD_CASE_CARD_DETAILS_SUCCESS:
            if (action.payload.title === "symptom") {
                state.symptoms = action.payload.casedetail
                state.addCaseCardDetails = NotifyMessages.SYMPTOM_ADD_SUCCESS;
            } else if (action.payload.title === "diagnostic") {
                state.diagnostoics = action.payload.casedetail
                state.addCaseCardDetails = NotifyMessages.DIAGNOSTIC_ADD_SUCCESS;
            } else if (action.payload.title === "imaging") {
                state.addCaseCardDetails = NotifyMessages.IMAGING_ADD_SUCCESS;
                state.imagings = action.payload.casedetail
            } else if (action.payload.title === "surgery") {
                state.addCaseCardDetails = NotifyMessages.SURGERY_ADD_SUCCESS;
                state.surgeries = action.payload.casedetail
            } else if (action.payload.title === "medication") {
                state.addCaseCardDetails = NotifyMessages.MEDICATION_ADD_SUCCESS;
                state.medications = action.payload.casedetail
            } else if (action.payload.title === "hospitalization") {
                state.addCaseCardDetails = NotifyMessages.HOSPITALIZATION_ADD_SUCCESS;
                state.hospitalization = action.payload.casedetail
            }
            return { ...state, loading: false, error: '' };
        case ADD_CASE_CARD_DETAILS_ERROR:
            return { ...state, loading: false, addCaseCardDetails: '', addCaseError: 'Case not Added', error: action.payload.message };


        case UPDATE_SYMPTOM:
            return { ...state, loading: true, symptomUpdate: '', symptomUpdateError: '', error: '' };
        case UPDATE_SYMPTOM_SUCCESS:
            return { ...state, loading: false, symptomUpdated: reducerSuccessAndErrortexts.UPDATE_SYMPTOM_SUCCESS, symptomUpdateError: '', symptoms: action.payload.symptom, error: '' };
        case UPDATE_SYMPTOM_ERROR:
            return { ...state, loading: false, symptomUpdated: '', symptomUpdateError: reducerSuccessAndErrortexts.UPDATE_SYMPTOM_ERROR, error: action.payload.message };

        case DELETE_SYMPTOM:
            return { ...state, loading: true, deletedSymptom: '', deletedSymptomError: '', error: '' };
        case DELETE_SYMPTOM_SUCCESS:
            return { ...state, loading: false, deletedSymptom: reducerSuccessAndErrortexts.DELETE_SYMPTOM_SUCCESS, deletedSymptomError: '', symptoms: action.payload.symptom, error: '' };
        case DELETE_SYMPTOM_ERROR:
            return { ...state, loading: false, deletedSymptom: '', deletedSymptomError: reducerSuccessAndErrortexts.DELETE_SYMPTOM_ERROR, error: action.payload.message };

        case CHANGE_CASE_STATUS:
            return { ...state, loading: true, changeStatus: '', changeStatusError: '', error: '' };
        case CHANGE_CASE_STATUS_SUCCESS:
            state.caseDetails.Status = action.payload.case.data.Status;
            return { ...state, loading: false, changeStatus: reducerSuccessAndErrortexts.CHANGE_CASE_STATUS_SUCCESS, changeStatusError: '', error: '' };
        case CHANGE_CASE_STATUS_ERROR:
            return { ...state, loading: false, changeStatus: '', changeStatusError: reducerSuccessAndErrortexts.CHANGE_CASE_STATUS_ERROR, error: action.payload.message };

        case CREATE_NEW_CASE:
            return { ...state, loading: true, newCaseCreated: '', newCaseCreatedError: '', error: '' };
        case CREATE_NEW_CASE_SUCCESS:
            if (action.payload.caseMessage) {
                state.caseExistMessage = action.payload.caseMessage
            } else {
                state.caseListCount += 1;
                state.cases.pop()
                state.cases = [...action.payload.case, ...state.cases];
                state.newCaseCreated = reducerSuccessAndErrortexts.CREATE_NEW_CASE_SUCCESS;
            }
            return { ...state, loading: false, newCaseCreatedError: '', error: '' };
        case CREATE_NEW_CASE_ERROR:
            return { ...state, loading: false, newCaseCreated: '', newCaseCreatedError: reducerSuccessAndErrortexts.CREATE_NEW_CASE_ERROR, error: action.payload.message };

        case GET_CASE_MANAGERS_LIST:
            return { ...state, loading: true, getCMList: false, getcaseManagerListSuccess: '', getcaseManagerListError: '', error: '' };
        case GET_CASE_MANAGERS_LIST_SUCCESS:
            return { ...state, loading: false, getCMList: true, getcaseManagerListSuccess: "case mansgers listed", caseManagersList: action.payload.caseManagersList, getcaseManagerListError: '', error: '' };
        case GET_CASE_MANAGERS_LIST_ERROR:
            return { ...state, loading: false, getCMList: true, getcaseManagerListSuccess: '', getcaseManagerListError: 'case mansgers not listed', error: action.payload.message };

        case ASSIGN_CASE_MANAGER:
            return { ...state, loading: true, caseManagerAssigned: '', caseManagerAssignedError: '', error: '' };
        case ASSIGN_CASE_MANAGER_SUCCESS:
            if (action.payload.from === "caseDtl") {
                state.case_managers = action.payload.caseManagers;
            } else {
                const index = getItemIndex(state.cases, action.payload.tmaNumber);
                state.cases[index].Case_manager = action.payload.caseManagers;
            }
            return { ...state, loading: false, caseManagerAssigned: reducerSuccessAndErrortexts.ASSIGN_CASE_MANAGER_SUCCESS, caseManagerAssignedError: '', error: '' };
        case ASSIGN_CASE_MANAGER_ERROR:
            return { ...state, loading: false, caseManagerAssigned: '', caseManagerAssignedError: reducerSuccessAndErrortexts.ASSIGN_CASE_MANAGER_ERROR, error: action.payload.message };

        case REMOVE_CASE_MANAGER:
            return { ...state, loading: true, caseManagerRemoved: '', caseManagerRemovedError: '', error: '' };
        case REMOVE_CASE_MANAGER_SUCCESS:
            const caseManagerindex = getCaseManagerIndex(state.case_managers, action.payload.username);
            state.case_managers.splice(caseManagerindex, 1)
            return { ...state, loading: false, caseManagerRemoved: reducerSuccessAndErrortexts.REMOVE_CASE_MANAGER_SUCCESS, caseManagerRemovedError: '', error: '' };
        case REMOVE_CASE_MANAGER_ERROR:
            return { ...state, loading: false, caseManagerRemoved: '', caseManagerRemovedError: reducerSuccessAndErrortexts.REMOVE_CASE_MANAGER_ERROR, error: action.payload.message };


        case DELETE_CASE:
            return { ...state, loading: true, caseDeleted: '', caseDeletedError: '', error: '' };
        case DELETE_CASE_SUCCESS:
            const deletedCaseIndex = getItemIndex(state.cases, action.payload.deleteCaseId);
            state.cases.splice(deletedCaseIndex, 1);
            return { ...state, loading: false, caseDeleted: reducerSuccessAndErrortexts.DELETE_CASE_SUCCESS, caseDeletedError: '', error: '' };
        case DELETE_CASE_ERROR:
            return { ...state, loading: false, caseDeleted: '', caseDeletedError: reducerSuccessAndErrortexts.DELETE_CASE_ERROR, error: action.payload.message };

        case UPDATE_KEY_FINDINGS:
            return { ...state, loading: true, keyFindingUpdated: '', keyfindingError: '', error: '' };
        case UPDATE_KEY_FINDINGS_SUCCESS:
            state.freeform = action.payload.description
            return { ...state, loading: false, keyFindingUpdated: reducerSuccessAndErrortexts.UPDATE_KEY_FINDINGS_SUCCESS, keyfindingError: '', error: '' };
        case UPDATE_KEY_FINDINGS_ERROR:
            return { ...state, loading: false, keyFindingUpdated: '', keyfindingError: reducerSuccessAndErrortexts.UPDATE_KEY_FINDINGS_ERROR, error: action.payload.message };

        case GET_CASE_ANALYTICS:
            return { ...state, getCaseAnalytics: false, caseAnalyticsSuccess: '', caseAnalyticsError: '', error: '' };
        case GET_CASE_ANALYTICS_SUCCESS:
            return { ...state, loading: false, getCaseAnalytics: true, caseAnalytics: action.payload.caseAnalytics, caseAnalyticsSuccess: reducerSuccessAndErrortexts.GET_CASE_ANALYTICS_SUCCESS, caseAnalyticsError: '', error: '' };
        case GET_CASE_ANALYTICS_ERROR:
            return { ...state, loading: false, getCaseAnalytics: true, caseAnalyticsSuccess: '', caseAnalyticsError: reducerSuccessAndErrortexts.GET_CASE_ANALYTICS_ERROR, error: action.payload.message };

        case ADD_MALACARD_TO_CASE:
            return { ...state, loading: true, addMalaCardLoading: false, addMalaCardtoCaseSuccess: '', addMalaCardtoCaseError: '', error: '' };
        case ADD_MALACARD_TO_CASE_SUCCESS:
            return { ...state, loading: false, addMalaCardLoading: true, addMalaCardtoCaseSuccess: reducerSuccessAndErrortexts.ADD_MALACARD_TO_CASE_SUCCESS, addMalaCardtoCaseError: '', error: '' };
        case ADD_MALACARD_TO_CASE_ERROR:
            return { ...state, loading: false, addMalaCardLoading: true, addMalaCardtoCaseSuccess: '', addMalaCardtoCaseError: reducerSuccessAndErrortexts.GET_CASE_ANALYTICS_ERROR, error: action.payload.message };

        case REMOVE_MALACARD_FROM_CASE:
            return { ...state, loading: true, removedMalaCard: '', removedMalaCardError: '', error: '' };
        case REMOVE_MALACARD_FROM_CASE_SUCCESS:
            const removedMalacardCaseIndex = removeAnalyticsIndex(state.caseAnalytics, action.payload.caseAnalyticsId);
            state.caseAnalytics.splice(removedMalacardCaseIndex, 1);
            return { ...state, loading: false, removedMalaCard: reducerSuccessAndErrortexts.REMOVE_MALACARD_FROM_CASE_SUCCESS, removedMalaCardError: '', error: '' };
        case REMOVE_MALACARD_FROM_CASE_ERROR:
            return { ...state, loading: false, removedMalaCard: '', removedMalaCardError: reducerSuccessAndErrortexts.REMOVE_MALACARD_FROM_CASE_ERROR, error: action.payload.message };

        default: return { ...state };
    }
}
