
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    LOGIN_USER,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    CHANGE_PASSWORD,
    RESET_PASSWORD
} from '../Actions';
import UserPool from '../../Constants/UserPool';
import { Auth } from 'aws-amplify';

import {
    loginUserSuccess,
    loginUserError,
    forgotPasswordSuccess,
    forgotPasswordError,
    changePasswordSuccess,
    changePasswordError,
    resetPasswordSuccess,
    resetPasswordError
} from './action';

const loginWithEmailPasswordAsync = async (payload) => {
    const { email, password } = payload.user;
    try {
        const user = await Auth.signIn(email.trim(), password.trim());
        user.status = true;
        return user;
    } catch (error) {
        error.status = false;
        return error
    }
}

function* loginWithEmailPassword({ payload }) {
    const { history } = payload;
    const response = yield loginWithEmailPasswordAsync(payload);
    if (response.status && response.Session === null) {
        response.getSignInUserSession().getAccessToken().payload.email = response.attributes.email;
        yield put(loginUserSuccess(response.getSignInUserSession().getAccessToken().payload));
        history.push('/');
    } else if (response.status && response.getSignInUserSession() === null) {
        response.message = "role";
        yield put(loginUserError(response.message));
    } else if(response.message==="User is disabled."){
        response.message = "Disabled";
        yield put(loginUserError(response.message));
    }else{
        response.message = "Incorrect username or password";
        yield put(loginUserError(response.message));
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}



const logoutAsync = async () => {
    Auth.signOut()
}

function* logout({ payload }) {
    const { history } = payload
    try {
        yield call(logoutAsync, history);
    }
    catch (error) {
    }
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}


const changePasswordAsync = async (password) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const result = await Auth.changePassword(user, password.old_password, password.new_password);
        return result;
    }
    catch (error) {
        error.status = 'error';
        return error;
    }
}

function* changePassword({ payload }) {
    const { password } = payload;
    const changePasswordStatus = yield changePasswordAsync(password);
    if (changePasswordStatus.status === 'error') {
        yield put(changePasswordError(changePasswordStatus.message));
    } else {
        yield put(changePasswordSuccess("Success"));
    }
}

export function* watchChangePassword() {
    yield takeEvery(CHANGE_PASSWORD, changePassword);
}

const forgotPasswordAsync = async (email) => {
    try {
        const result = await Auth.forgotPassword(email);
        return result.CodeDeliveryDetails
    }
    catch (error) {
        return error;
    }
}
function* forgotPassword({ payload }) {
    const { email } = payload;
    const forgotPasswordStatus = yield forgotPasswordAsync(email);
    if (forgotPasswordStatus.AttributeName) {
        yield put(forgotPasswordSuccess(forgotPasswordStatus.AttributeName));
    } else {
        yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
}

export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}


export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

function* resetPassword({ payload }) {
    const { verificationCode, newPassword, email } = payload;
    try {
        const resetPasswordStatus = yield call(resetPasswordAsync, verificationCode, newPassword, email);
        if (resetPasswordStatus === "SUCCESS") {
            yield put(resetPasswordSuccess("success"));
        } else {
            yield put(resetPasswordError(resetPasswordStatus.message));
        }
    } catch (error) {
        yield put(resetPasswordError(error));

    }
}

const resetPasswordAsync = async (verificationCode, newPassword, email) => {
    try {
        const result = await Auth.forgotPasswordSubmit(email.trim(), verificationCode.trim(), newPassword.trim());
        return result;
    }
    catch (err) {
        return err
    }
}
export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchChangePassword),
        fork(watchForgotPassword),
        fork(watchResetPassword)
    ]);
}