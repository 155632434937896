
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
    LOAD_USERS,
    USERS_ACTIVE_AND_INACTIVE,
    INVITE_CASE_MANAGER,
    CASE_COUNT,
    GET_SINGLE_USER,
    UPDATE_USER,
} from '../Actions';
import {
    loadUsersSuccess,
    loadUsersError,
    usersActicveAndInactiveSuccess,
    usersActicveAndInactiveError,
    inviteCaseManagerSuccess,
    inviteCaseManagerError,
    caseCountSuccess,
    caseCountError,
    getSingleUserSuccess,
    getSingleUserError,
    updateUserError,
    updateUserSuccess
} from './action';
import UserPool from '../../Constants/UserPool';
import { Auth } from 'aws-amplify';
import moment from 'moment';
import axios from 'axios';
import { newAws, loadUserParams, getUserGroups } from '../../Constants/utils';
import { adminConstant } from '../../config';
import ApiConstant from '../../Constants/apiConstants';
import { resolve } from 'dns';

const loadUsersAsync = async () => {
    try {
        //Just list the users. Do not divide them into groups
        const apis = [
            await axios.post(`${adminConstant}${ApiConstant.ONGOING_CASES_COUNT}`),
            await new Promise((resolve, reject) => newAws.listUsers(loadUserParams.all, (err, data) => resolve(data))),
            await new Promise((resolve, reject) => newAws.listUsersInGroup(loadUserParams.admin, (err, data) => resolve(data))),
            await new Promise((resolve, reject) => newAws.listUsersInGroup(loadUserParams.caseManager, (err, data) => resolve(data))),
            await new Promise((resolve, reject) => newAws.listUsersInGroup(loadUserParams.teamManager, (err, data) => resolve(data))),
            
        ];
        const response = await Promise.all(apis);
        const allUsersData = response[1];
        const adminData = response[2];
        const caseManagerData = response[3];
        const teamManagerData = response[4];        
        console.log(response[1])
        const { data: { data: ongoingCases } } = response[0];


        const adminUsers = adminData.Users.map( data => {
            return data.Username;
        });
        const teamUsers = teamManagerData.Users.map( data => {
            return data.Username;
        });
        const caseUsers = caseManagerData.Users.map( data => {
            return data.Username;
        });                

        allUsersData.Users.map(async (user) =>  {
            console.log("=============USER ==========")
            console.log(user)
            console.log(adminData.Users[0])

            if (adminUsers.includes(user.Username)) { 
                console.log("asdfasdfasdfasdfdsfasdf")
                user.user_type = "Admin";
            } else if (teamUsers.includes(user.Username)) user.user_type = "TeamManager";
            else if (caseUsers.includes(user.Username)) user.user_type = "CaseManager";
            else user.user_type = "User";

            return user;
        });  

        return { allUsers: allUsersData, adminData: adminData, caseManagerData: caseManagerData, teamManagerData: teamManagerData, ongoingCases: ongoingCases }
    }
    catch (e) {
        return e;
    }
}
function* loadUsers() {
    try {
        const userDatas = yield loadUsersAsync();
        
        if (userDatas) {
            // userDatas.adminData && userDatas.adminData.Users.map(data => { data.user_type = "Admin" });
            // userDatas.caseManagerData && userDatas.caseManagerData.Users.map(data => { data.user_type = "CaseManager" });
            // userDatas.teamManagerData && userDatas.teamManagerData.Users.map(data => { data.user_type = "TeamManager" });
            // const allUserFromGroup = userDatas.adminData && userDatas.adminData.Users.concat(userDatas.caseManagerData && userDatas.caseManagerData.Users, userDatas.teamManagerData && userDatas.teamManagerData.Users)
            
            const allUserFromGroup = userDatas.allUsers.Users;
            
            console.log(userDatas.ongoingCases)   
            if (userDatas.ongoingCases) {
                console.log("HERE")
                console.log(allUserFromGroup) 
                allUserFromGroup.map(user => {
                    console.log(user)
                    return Object.keys(userDatas.ongoingCases[0]).map(elem => {
                        if (user.Username === elem) user.onGoinCases = userDatas.ongoingCases[0][elem]
                    });
                })
            }

            allUserFromGroup.sort((a, b) => new Date(b.UserCreateDate) - new Date(a.UserCreateDate))
            yield put(loadUsersSuccess({ allUserFromGroup: allUserFromGroup }));
        }

        else {
            yield put(loadUsersError("error"));
        }
    }
    catch (err) {
        yield put(loadUsersError("error"));

    }
}

export function* watchLoadUsers() {
    yield takeEvery(LOAD_USERS, loadUsers);
}



const userActiveAndInactiveAsync = async (userName, userType, userStatus, mongoData) => {
    const userActiveInActiveParams = { Username: userName, UserPoolId: UserPool.userPoolId }
    if (userStatus === "Active") {
        const disabledResult = await new Promise((resolve, reject) => {
            newAws.adminDisableUser(userActiveInActiveParams, (err, data) => {
                if (err) {
                    const result = { status: false, userType: userType, userName: userActiveInActiveParams.Username, message: "error" };
                    reject(result)
                }
                if (data) {
                    const result = { status: true, userType: userType, userName: userActiveInActiveParams.Username, message: "In-Active" };
                    resolve(result)
                };
            });
        });
        await axios.post(`${adminConstant}${ApiConstant.CHANGE_USER_STATUS_MONGO}`, JSON.stringify(mongoData))
        return disabledResult;
    }
    if (userStatus === "In-Active") {
        const enabledResult = await new Promise((resolve, reject) => {
            newAws.adminEnableUser(userActiveInActiveParams, (err, data) => {
                if (err) {
                    const result = { status: false, userType: userType, userName: userActiveInActiveParams.Username, message: "error" };
                    reject(result)
                }
                if (data) {
                    const result = { status: true, userType: userType, userName: userActiveInActiveParams.Username, message: "Active" };
                    resolve(result)
                };
            });
        });
        await axios.post(`${adminConstant}${ApiConstant.CHANGE_USER_STATUS_MONGO}`, JSON.stringify(mongoData))
        return enabledResult;

    }


}
function* userActiveAndInactive({ payload }) {
    const { mongoData, userName, userStatus, userType } = payload;
    const userActiveAndInActiveStatus = yield userActiveAndInactiveAsync(userName, userType, userStatus, mongoData);

    if (userActiveAndInActiveStatus.status) {
        yield put(usersActicveAndInactiveSuccess(userActiveAndInActiveStatus));
    } else {
        yield put(usersActicveAndInactiveError(userActiveAndInActiveStatus));
    }
}

export function* watchUsersActive() {
    yield takeEvery(USERS_ACTIVE_AND_INACTIVE, userActiveAndInactive);
}

export function* watchInviteCaseManager() {
    yield takeEvery(INVITE_CASE_MANAGER, inviteCaseManager);
}

function* inviteCaseManager({ payload }) {
    const { userName, attributes } = payload;
    const inviteCaseManagerStatus = yield inviteCaseManagerAsync(userName, attributes);
    if (inviteCaseManagerStatus.status) {
        const addInviteCaseManagerStatus = yield addInviteCaseManagerInGroupAsync(inviteCaseManagerStatus);
        if (addInviteCaseManagerStatus.status) yield put(inviteCaseManagerSuccess({ user: inviteCaseManagerStatus.User, successMessage: "Invited" }));
        else yield put(inviteCaseManagerError("Not Invited"));
    } else yield put(inviteCaseManagerError("An account with the given email already exists"));
}



const inviteCaseManagerAsync = async (userName, attributes) => {
    const params = { Username: userName, UserAttributes: attributes, UserPoolId: UserPool.userPoolId }

    const inviteCaseManager = await new Promise((resolve, reject) => {
        newAws.adminCreateUser(params, (err, data) => {
            if (err) resolve(err)
            else { data.status = true; resolve(data) }
        })
    });
    if (inviteCaseManager.status) {
        inviteCaseManager.User.user_type = "CaseManager";
        try {
            const mongoData = {
                username: inviteCaseManager.User.Username,
                given_name: inviteCaseManager.User.Attributes.find(elem => elem.Name === "given_name").Value,
                middle_name: inviteCaseManager.User.Attributes.find(elem => elem.Name === "middle_name").Value,
                enabled: inviteCaseManager.User.Enabled,
                userstatus: inviteCaseManager.User.UserStatus,
                email: inviteCaseManager.User.Attributes.find(elem => elem.Name === "email").Value
            }
            await axios.post(`${adminConstant}${ApiConstant.CREATE_CASE_MANAGER_MONGO}`, JSON.stringify(mongoData))
        }
        catch (err) {
            console.error(err)
        }
    }

    return inviteCaseManager
}


const addInviteCaseManagerInGroupAsync = async (invitedCasemanager) => {
    const params = { UserPoolId: UserPool.userPoolId, Username: invitedCasemanager.User.Username, GroupName: 'CaseManager' };

    const addInviteCaseManagerInGroup = await new Promise((resolve, reject) => {
        newAws.adminAddUserToGroup(params, (err, data) => {
            if (err) reject(err);
            else { data.status = true; resolve(data) }
        })
    });

    return addInviteCaseManagerInGroup;
}

export function* watchGetCaseCount() {
    yield takeEvery(CASE_COUNT, getCaseCount);
}

function* getCaseCount({ payload }) {
    const caseCountStatus = yield getCaseCountAsync();
    if (caseCountStatus.status) yield put(caseCountSuccess(caseCountStatus.data.data));
    else yield put(caseCountError(caseCountStatus.message));
}

const getCaseCountAsync = async () => {
    try {
        const result = await axios.post(`${adminConstant}${ApiConstant.GET_CASE_COUNT}`);
        return result;
    }
    catch (err) {
        err.message = "not Fetched"
        return err
    }
}


export function* watchGetSingleUser() {
    yield takeEvery(GET_SINGLE_USER, getSingleUser);
}

function* getSingleUser({ payload }) {

    const { userName } = payload;
    const singleUserStatus = yield getSingleUserAsync(userName);

    if (singleUserStatus.Username) yield put(getSingleUserSuccess(singleUserStatus));
    else yield put(getSingleUserError("User Not Found"));
}

const getSingleUserAsync = async (userName) => {
    const params = { Username: userName, UserPoolId: UserPool.userPoolId }
    try {
        const adminData = await new Promise((resolve, reject) => {
            newAws.adminGetUser(params, (err, data) => {
                if (err) reject(err)
                else resolve(data)
            })
        });
        return adminData;
    }
    catch (err) {
        err.message = "not Fetched"
        return err
    }
}

export function* watchupdateUser() {
    yield takeEvery(UPDATE_USER, updateuser);
}

function* updateuser({ payload }) {
    const { userName, attributes, mongoData } = payload;
    const updateUserStatus = yield updateuserAsync(userName, attributes, mongoData);

    if (updateUserStatus.status) yield put(updateUserSuccess({ attributes: attributes, message: "updated" }));
    else yield put(updateUserError("User Not Update"));

}

const updateuserAsync = async (userName, attributes, mongoData) => {
    const params = { UserAttributes: attributes, Username: userName, UserPoolId: UserPool.userPoolId }

    try {
        const adminData = await new Promise((resolve, reject) => {
            newAws.adminUpdateUserAttributes(params, (err, data) => {
                if (err) {
                    reject(err)
                }
                else { const successData = { status: true }; resolve(successData) }
            })
        });
        await axios.post(`${adminConstant}${ApiConstant.EDIT_CASE_MONGO}`, JSON.stringify(mongoData))
        return adminData;
    }
    catch (err) {
        err.message = "not Fetched"
        return err
    }
}
export default function* rootSaga() {
    yield all([
        fork(watchLoadUsers),
        fork(watchUsersActive),
        fork(watchInviteCaseManager),
        fork(watchGetCaseCount),
        fork(watchGetSingleUser),
        fork(watchupdateUser)

    ]);
}