import {
    LOAD_USERS,
    LOAD_USERS_SUCCESS,
    LOAD_USERS_ERROR,
    USERS_ACTIVE_AND_INACTIVE,
    USERS_ACTIVE_AND_INACTIVE_SUCCESS,
    USERS_ACTIVE_AND_INACTIVE_ERROR,
    INVITE_CASE_MANAGER,
    INVITE_CASE_MANAGER_SUCCESS,
    INVITE_CASE_MANAGER_ERROR,
    CASE_COUNT,
    CASE_COUNT_SUCCESS,
    CASE_COUNT_ERROR,
    GET_SINGLE_USER,
    GET_SINGLE_USER_SUCCESS,
    GET_SINGLE_USER_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
} from '../Actions';

const INIT_STATE = {
    users: [],
    cases: [],
    loading: true,
    error: ''
};

const getItemIndex = (users, user) => {
    return users.findIndex(d => d.user_type === user.userType && d.Attributes.find(elem => elem.Name === "email").Value === user.userEmail)
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_USERS:
            return { ...state, updateUser: '', loading: true, error: '' };
        case LOAD_USERS_SUCCESS:
            return { ...state, loading: false, loadDashboard: true, users: action.payload.allUserFromGroup, error: '' };
        case LOAD_USERS_ERROR:
            return { ...state, loading: false, users: null, error: action.payload.message };

        case USERS_ACTIVE_AND_INACTIVE:
            return { ...state, loading: true, error: '' };
        case USERS_ACTIVE_AND_INACTIVE_SUCCESS:
            const index = getItemIndex(state.users, { userEmail: action.payload.userName, userType: action.payload.userType });

            if (action.payload.message === "Active") {
                state.users[index].Enabled = true;
                state.users[index].UserStatus = "CONFIRMED";
            } else {
                state.users[index].Enabled = false;
            }
            return {
                ...state, loading: false, error: ''
            };
        case USERS_ACTIVE_AND_INACTIVE_ERROR:
            return { ...state, loading: false, error: action.payload.message };
        case INVITE_CASE_MANAGER:
            return { ...state, loading: true, updateUser: '', updateUserError: '', inviteError: '', inviteMessage: '', error: '' };
        case INVITE_CASE_MANAGER_SUCCESS:
            return { ...state, loading: false, users: [action.payload.user, ...state.users], inviteMessage: action.payload.successMessage, error: '' };
        case INVITE_CASE_MANAGER_ERROR:
            return { ...state, loading: false, inviteError: action.payload, error: '' };
        case CASE_COUNT:
            return { ...state, loading: true, error: '' };
        case CASE_COUNT_SUCCESS:
            return { ...state, loading: false, caseCount: action.payload, error: '' };
        case CASE_COUNT_ERROR:
            return { ...state, loading: false, casecount: '', error: '' };
        case GET_SINGLE_USER:
            return { ...state, loading: true, updateUser: '', singleUserError: '', user: '', error: '' };
        case GET_SINGLE_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload, error: '' };
        case GET_SINGLE_USER_ERROR:
            return { ...state, loading: false, singleUserError: action.payload, error: '' };
        case UPDATE_USER:
            return { ...state, loading: true, updateUser: '', updateUserError: '', error: '' };
        case UPDATE_USER_SUCCESS:
            state.user.UserAttributes = [...action.payload.attributes, ...state.user.UserAttributes]
            return { ...state, loading: false, updateUser: action.payload.message, error: '' };
        case UPDATE_USER_ERROR:
            return { ...state, loading: false, updateUserError: action.payload, error: '' };
        default: return { ...state };
    }
}
