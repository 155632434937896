import {
    LOAD_USERS,
    LOAD_USERS_SUCCESS,
    LOAD_USERS_ERROR,
    USERS_ACTIVE_AND_INACTIVE,
    USERS_ACTIVE_AND_INACTIVE_SUCCESS,
    USERS_ACTIVE_AND_INACTIVE_ERROR,
    INVITE_CASE_MANAGER,
    INVITE_CASE_MANAGER_SUCCESS,
    INVITE_CASE_MANAGER_ERROR,
    CASE_COUNT,
    CASE_COUNT_SUCCESS,
    CASE_COUNT_ERROR,
    GET_SINGLE_USER,
    GET_SINGLE_USER_SUCCESS,
    GET_SINGLE_USER_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR
} from '../Actions';


export const loadUsers = (userData) => ({
    type: LOAD_USERS,
    payload: userData
});
export const loadUsersSuccess = (user) => ({
    type: LOAD_USERS_SUCCESS,
    payload: user
});
export const loadUsersError = (message) => ({
    type: LOAD_USERS_ERROR,
    payload: { message }
});

export const usersActicveAndInactive = (userData) => ({
    type: USERS_ACTIVE_AND_INACTIVE,
    payload: userData
});
export const usersActicveAndInactiveSuccess = (user) => ({
    type: USERS_ACTIVE_AND_INACTIVE_SUCCESS,
    payload: user
});
export const usersActicveAndInactiveError = (message) => ({
    type: USERS_ACTIVE_AND_INACTIVE_ERROR,
    payload: { message }
});

export const inviteCaseManager = (userData) => ({
    type: INVITE_CASE_MANAGER,
    payload: userData
});
export const inviteCaseManagerSuccess = (user) => ({
    type: INVITE_CASE_MANAGER_SUCCESS,
    payload: user
});
export const inviteCaseManagerError = (message) => ({
    type: INVITE_CASE_MANAGER_ERROR,
    payload: { message }
});

export const caseCount = () => ({
    type: CASE_COUNT
});
export const caseCountSuccess = (count) => ({
    type: CASE_COUNT_SUCCESS,
    payload: count
});
export const caseCountError = (message) => ({
    type: CASE_COUNT_ERROR,
    payload: { message }
});

export const getSingleUser = (user) => ({
    type: GET_SINGLE_USER,
    payload: user
});
export const getSingleUserSuccess = (user) => ({
    type: GET_SINGLE_USER_SUCCESS,
    payload: user
});
export const getSingleUserError = (message) => ({
    type: GET_SINGLE_USER_ERROR,
    payload: message
});

export const updateUser = (user) => ({
    type: UPDATE_USER,
    payload: user
});
export const updateUserSuccess = (user) => ({
    type: UPDATE_USER_SUCCESS,
    payload: user
});
export const updateUserError = (message) => ({
    type: UPDATE_USER_ERROR,
    payload: message
});

// export const uploadProfilePicture = (user) => ({
//     type: UPLOAD_PROFILE_PICTURE,
//     payload: user
// });
// export const uploadProfilePictureSuccess = (user) => ({
//     type: UPLOAD_PROFILE_PICTURE_SUCCESS,
//     payload: user
// });
// export const uploadProfilePictureError = (message) => ({
//     type: UPLOAD_PROFILE_PICTURE_ERROR,
//     payload: message
// });
