import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    LOGIN_USER_ERROR,
    CHECK_USER_TOKEN,
    CHECK_USER_TOKEN_VALID,
    CHECK_USER_TOKEN_INVALID,
    CHECK_USER_TOKEN_ERROR,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
} from '../Actions';


export const loginUser = (user, history) => ({
    type: LOGIN_USER,
    payload: { user, history }
});
export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user
});
export const loginUserError = (message) => ({
    type: LOGIN_USER_ERROR,
    payload: { message }
});

export const checkUserToken = (history) => ({
    type: CHECK_USER_TOKEN,
    payload: { history }
});

export const checkUserTokenValid = (user) => ({
    type: CHECK_USER_TOKEN_VALID,
    payload: user
});

export const checkUserTokenInValid = (message) => ({
    type: CHECK_USER_TOKEN_INVALID,
    payload: { message }
});

export const checkUserTokenError = (message) => ({
    type: CHECK_USER_TOKEN_ERROR,
    payload: { message }
});

export const forgotPassword = (email) => ({
    type: FORGOT_PASSWORD,
    payload: email
});
export const forgotPasswordSuccess = (message) => ({
    type: FORGOT_PASSWORD_SUCCESS,
    payload: message
});
export const forgotPasswordError = (message) => ({
    type: FORGOT_PASSWORD_ERROR,
    payload: { message }
});

export const resetPassword = ({ verificationCode, newPassword, email }) => ({
    type: RESET_PASSWORD,
    payload: { verificationCode, newPassword, email }
});
export const resetPasswordSuccess = (newPassword) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: newPassword
});
export const resetPasswordError = (message) => ({
    type: RESET_PASSWORD_ERROR,
    payload: { message }
});

export const changePassword = (password) => ({
    type: CHANGE_PASSWORD,
    payload: { password }
});
export const changePasswordSuccess = (message) => ({
    type: CHANGE_PASSWORD_SUCCESS,
    payload: message
});
export const changePasswordError = (message) => ({
    type: CHANGE_PASSWORD_ERROR,
    payload: { message }
});

export const signOutUser = (history) => ({
    type: LOGOUT_USER,
    payload: { history }
});

