import {
    CASE_LIST,
    CASE_LIST_SUCCESS,
    CASE_LIST_ERROR,
    CASE_DETAILS,
    CASE_DETAILS_SUCCESS,
    CASE_DETAILS_ERROR,
    UPDATE_CASE_DETAILS,
    UPDATE_CASE_DETAILS_SUCCESS,
    UPDATE_CASE_DETAILS_ERROR,
    ADD_CASE_CARD_DETAILS,
    ADD_CASE_CARD_DETAILS_SUCCESS,
    ADD_CASE_CARD_DETAILS_ERROR,
    UPDATE_SYMPTOM,
    UPDATE_SYMPTOM_SUCCESS,
    UPDATE_SYMPTOM_ERROR,
    DELETE_SYMPTOM,
    DELETE_SYMPTOM_SUCCESS,
    DELETE_SYMPTOM_ERROR,
    CHANGE_CASE_STATUS,
    CHANGE_CASE_STATUS_SUCCESS,
    CHANGE_CASE_STATUS_ERROR,
    CREATE_NEW_CASE,
    CREATE_NEW_CASE_SUCCESS,
    CREATE_NEW_CASE_ERROR,
    ASSIGN_CASE_MANAGER,
    ASSIGN_CASE_MANAGER_SUCCESS,
    ASSIGN_CASE_MANAGER_ERROR,
    REMOVE_CASE_MANAGER,
    REMOVE_CASE_MANAGER_SUCCESS,
    REMOVE_CASE_MANAGER_ERROR,
    GET_CASE_MANAGERS_LIST,
    GET_CASE_MANAGERS_LIST_SUCCESS,
    GET_CASE_MANAGERS_LIST_ERROR,
    DELETE_CASE,
    DELETE_CASE_SUCCESS,
    DELETE_CASE_ERROR,
    UPDATE_KEY_FINDINGS,
    UPDATE_KEY_FINDINGS_SUCCESS,
    UPDATE_KEY_FINDINGS_ERROR,
    GET_CASE_ANALYTICS,
    GET_CASE_ANALYTICS_SUCCESS,
    GET_CASE_ANALYTICS_ERROR,
    ADD_MALACARD_TO_CASE,
    ADD_MALACARD_TO_CASE_SUCCESS,
    ADD_MALACARD_TO_CASE_ERROR,
    REMOVE_MALACARD_FROM_CASE,
    REMOVE_MALACARD_FROM_CASE_SUCCESS,
    REMOVE_MALACARD_FROM_CASE_ERROR
} from '../Actions';


export const casesList = (caseParam) => ({
    type: CASE_LIST,
    payload: caseParam
});
export const casesListSuccess = (cases) => ({
    type: CASE_LIST_SUCCESS,
    payload: cases
});
export const casesListError = (message) => ({
    type: CASE_LIST_ERROR,
    payload: { message }
});


export const caseDetails = (tma) => ({
    type: CASE_DETAILS,
    payload: tma
});
export const caseDetailsSuccess = (data) => ({
    type: CASE_DETAILS_SUCCESS,
    payload: data
});
export const caseDetailsError = (message) => ({
    type: CASE_DETAILS_ERROR,
    payload: { message }
});

export const caseDetailsOnlySuccess = (data) => ({
    type: CASE_DETAILS_SUCCESS,
    payload: data
});
export const caseDetailsOnlyError = (message) => ({
    type: CASE_DETAILS_ERROR,
    payload: { message }
});

export const updateCaseDetails = (data) => ({
    type: UPDATE_CASE_DETAILS,
    payload: data
});
export const updateCaseDetailsSuccess = (data) => ({
    type: UPDATE_CASE_DETAILS_SUCCESS,
    payload: data
});
export const updateCaseDetailsError = (message) => ({
    type: UPDATE_CASE_DETAILS_ERROR,
    payload: { message }
});

export const addCaseCardDetails = (data) => ({
    type: ADD_CASE_CARD_DETAILS,
    payload: data
});
export const addCaseCardDetailsSuccess = (data) => ({
    type: ADD_CASE_CARD_DETAILS_SUCCESS,
    payload: data
});
export const addCaseCardDetailsError = (message) => ({
    type: ADD_CASE_CARD_DETAILS_ERROR,
    payload: { message }
});

export const updateSymptom = (symptom) => ({
    type: UPDATE_SYMPTOM,
    payload: symptom
});
export const updateSymptomSuccess = (data) => ({
    type: UPDATE_SYMPTOM_SUCCESS,
    payload: data
});
export const updateSymptomError = (message) => ({
    type: UPDATE_SYMPTOM_ERROR,
    payload: { message }
});

export const deleteSymptom = (symptom) => ({
    type: DELETE_SYMPTOM,
    payload: symptom
});
export const deleteSymptomSuccess = (data) => ({
    type: DELETE_SYMPTOM_SUCCESS,
    payload: data
});
export const deleteSymptomError = (message) => ({
    type: DELETE_SYMPTOM_ERROR,
    payload: { message }
});
export const changeCaseStatus = (status) => ({
    type: CHANGE_CASE_STATUS,
    payload: status
});
export const changeCaseStatusSuccess = (data) => ({
    type: CHANGE_CASE_STATUS_SUCCESS,
    payload: data
});
export const changeCaseStatusError = (message) => ({
    type: CHANGE_CASE_STATUS_ERROR,
    payload: { message }
});


export const createNewCase = (caseData) => ({
    type: CREATE_NEW_CASE,
    payload: caseData
});
export const createNewCaseSuccess = (data) => ({
    type: CREATE_NEW_CASE_SUCCESS,
    payload: data
});
export const createNewCaseError = (message) => ({
    type: CREATE_NEW_CASE_ERROR,
    payload: { message }
});


export const getCaseManagersList = () => ({
    type: GET_CASE_MANAGERS_LIST
});
export const getCaseManagersListSuccess = (data) => ({
    type: GET_CASE_MANAGERS_LIST_SUCCESS,
    payload: data
});
export const getCaseManagersListError = (message) => ({
    type: GET_CASE_MANAGERS_LIST_ERROR,
    payload: { message }
});

export const assignCaseManager = (caseManager) => ({
    type: ASSIGN_CASE_MANAGER,
    payload: caseManager
});
export const assignCaseManagerSuccess = (data) => ({
    type: ASSIGN_CASE_MANAGER_SUCCESS,
    payload: data
});
export const assignCaseManagerError = (message) => ({
    type: ASSIGN_CASE_MANAGER_ERROR,
    payload: { message }
});

export const removeCaseManager = (caseManager) => ({
    type: REMOVE_CASE_MANAGER,
    payload: caseManager
});
export const removeCaseManagerSuccess = (data) => ({
    type: REMOVE_CASE_MANAGER_SUCCESS,
    payload: data
});
export const removeCaseManagerError = (message) => ({
    type: REMOVE_CASE_MANAGER_ERROR,
    payload: { message }
});

export const deleteCase = (caseData) => ({
    type: DELETE_CASE,
    payload: caseData
});
export const deleteCaseSuccess = (data) => ({
    type: DELETE_CASE_SUCCESS,
    payload: data
});
export const deleteCaseError = (message) => ({
    type: DELETE_CASE_ERROR,
    payload: { message }
});


export const updateKeyFindings = (freeform) => ({
    type: UPDATE_KEY_FINDINGS,
    payload: freeform
});
export const updateKeyFindingsSuccess = (data) => ({
    type: UPDATE_KEY_FINDINGS_SUCCESS,
    payload: data
});
export const updateKeyFindingsError = (message) => ({
    type: UPDATE_KEY_FINDINGS_ERROR,
    payload: { message }
});


export const getCaseAnalytics = (tma_num) => ({
    type: GET_CASE_ANALYTICS,
    payload: tma_num
});
export const getCaseAnalyticsSuccess = (data) => ({
    type: GET_CASE_ANALYTICS_SUCCESS,
    payload: data
});
export const getCaseAnalyticsError = (message) => ({
    type: GET_CASE_ANALYTICS_ERROR,
    payload: { message }
});

export const addMalaCardToCase = (_id) => ({
    type: ADD_MALACARD_TO_CASE,
    payload: _id
});
export const addMalaCardToCaseSuccess = (data) => ({
    type: ADD_MALACARD_TO_CASE_SUCCESS,
    payload: data
});
export const addMalaCardToCaseError = (message) => ({
    type: ADD_MALACARD_TO_CASE_ERROR,
    payload: { message }
});

export const removeMalaCardFromCase = (_id) => ({
    type: REMOVE_MALACARD_FROM_CASE,
    payload: _id
});
export const removeMalaCardFromCaseSuccess = (data) => ({
    type: REMOVE_MALACARD_FROM_CASE_SUCCESS,
    payload: data
});
export const removeMalaCardFromCaseError = (message) => ({
    type: REMOVE_MALACARD_FROM_CASE_ERROR,
    payload: { message }
});


