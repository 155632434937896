
/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const CHECK_USER_TOKEN = "CHECK_USER_TOKEN";
export const CHECK_USER_TOKEN_SUCCESS = "CHECK_USER_TOKEN_SUCCESS";
export const CHECK_USER_TOKEN_VALID = "CHECK_USER_TOKEN_VALID";
export const CHECK_USER_TOKEN_INVALID = "CHECK_USER_TOKEN_INVALID";
export const CHECK_USER_TOKEN_ERROR = "CHECK_USER_TOKEN_ERROR";

export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const INVITE_CASE_MANAGER = "INVITE_CASE_MANAGER";
export const INVITE_CASE_MANAGER_SUCCESS = "INVITE_CASE_MANAGER_SUCCESS";
export const INVITE_CASE_MANAGER_ERROR = "INVITE_CASE_MANAGER_ERROR";

/*USERS*/
export const LOAD_USERS = "LOAD_USERS";
export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const LOAD_USERS_ERROR = "LOAD_USERS_ERROR";
export const USERS_ACTIVE_AND_INACTIVE = "USERS_ACTIVE_AND_INACTIVE";
export const USERS_ACTIVE_AND_INACTIVE_SUCCESS = "USERS_ACTIVE_AND_INACTIVE_SUCCESS";
export const USERS_ACTIVE_AND_INACTIVE_ERROR = "USERS_ACTIVE_AND_INACTIVE_ERROR";

export const GET_SINGLE_USER = "GET_SINGLE_USER";
export const GET_SINGLE_USER_SUCCESS = "GET_SINGLE_USER_SUCCESS";
export const GET_SINGLE_USER_ERROR = "GET_SINGLE_USER_ERROR";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";


/*CASES*/
export const CASE_COUNT = "CASE_COUNT";
export const CASE_COUNT_SUCCESS = "CASE_COUNT_SUCCESS";
export const CASE_COUNT_ERROR = "CASE_COUNT_ERROR";

export const CASE_LIST = "CASE_LIST";
export const CASE_LIST_SUCCESS = "CASE_LIST_SUCCESS";
export const CASE_LIST_ERROR = "CASE_LIST_ERROR";

export const CASE_DETAILS = "CASE_DETAILS";
export const CASE_DETAILS_SUCCESS = "CASE_DETAILS_SUCCESS";
export const CASE_DETAILS_ERROR = "CASE_DETAILS_ERROR";

export const UPDATE_CASE_DETAILS = "UPDATE_CASE_DETAILS";
export const UPDATE_CASE_DETAILS_SUCCESS = "UPDATE_CASE_DETAILS_SUCCESS";
export const UPDATE_CASE_DETAILS_ERROR = "UPDATE_CASE_DETAILS_ERROR";

export const ADD_CASE_CARD_DETAILS = "ADD_CASE_CARD_DETAILS";
export const ADD_CASE_CARD_DETAILS_SUCCESS = "ADD_CASE_CARD_DETAILS_SUCCESS";
export const ADD_CASE_CARD_DETAILS_ERROR = "ADD_CASE_CARD_DETAILS_ERROR";


export const UPDATE_SYMPTOM = "UPDATE_SYMPTOM";
export const UPDATE_SYMPTOM_SUCCESS = "UPDATE_SYMPTOM_SUCCESS";
export const UPDATE_SYMPTOM_ERROR = "UPDATE_SYMPTOM_ERROR";

export const DELETE_SYMPTOM = "DELETE_SYMPTOM";
export const DELETE_SYMPTOM_SUCCESS = "DELETE_SYMPTOM_SUCCESS";
export const DELETE_SYMPTOM_ERROR = "DELETE_SYMPTOM_ERROR";

export const UPLOAD_PROFILE_PICTURE = "UPLOAD_PROFILE_PICTURE";
export const UPLOAD_PROFILE_PICTURE_SUCCESS = "UPLOAD_PROFILE_PICTURE_SUCCESS";
export const UPLOAD_PROFILE_PICTURE_ERROR = "UPLOAD_PROFILE_PICTURE_ERROR";

export const CHANGE_CASE_STATUS = "CHANGE_CASE_STATUS";
export const CHANGE_CASE_STATUS_SUCCESS = "CHANGE_CASE_STATUS_SUCCESS";
export const CHANGE_CASE_STATUS_ERROR = "CHANGE_CASE_STATUS_ERROR";


export const CREATE_NEW_CASE = "CREATE_NEW_CASE";
export const CREATE_NEW_CASE_SUCCESS = "CREATE_NEW_CASE_SUCCESS";
export const CREATE_NEW_CASE_ERROR = "CREATE_NEW_CASE_ERROR";

export const GET_CASE_MANAGERS_LIST = "GET_CASE_MANAGERS_LIST";
export const GET_CASE_MANAGERS_LIST_SUCCESS = "GET_CASE_MANAGERS_LIST_SUCCESS";
export const GET_CASE_MANAGERS_LIST_ERROR = "GET_CASE_MANAGERS_LIST_ERROR";

export const ASSIGN_CASE_MANAGER = "ASSIGN_CASE_MANAGER";
export const ASSIGN_CASE_MANAGER_SUCCESS = "ASSIGN_CASE_MANAGER_SUCCESS";
export const ASSIGN_CASE_MANAGER_ERROR = "ASSIGN_CASE_MANAGER_ERROR";

export const REMOVE_CASE_MANAGER = "REMOVE_CASE_MANAGER";
export const REMOVE_CASE_MANAGER_SUCCESS = "REMOVE_CASE_MANAGER_SUCCESS";
export const REMOVE_CASE_MANAGER_ERROR = "REMOVE_CASE_MANAGER_ERROR";

export const DELETE_CASE = "DELETE_CASE";
export const DELETE_CASE_SUCCESS = "DELETE_CASE_SUCCESS";
export const DELETE_CASE_ERROR = "DELETE_CASE_ERROR";

export const UPDATE_KEY_FINDINGS = "UPDATE_KEY_FINDINGS";
export const UPDATE_KEY_FINDINGS_SUCCESS = "UPDATE_KEY_FINDINGS_SUCCESS";
export const UPDATE_KEY_FINDINGS_ERROR = "UPDATE_KEY_FINDINGS_ERROR";


export const GET_CASE_ANALYTICS = "GET_CASE_ANALYTICS";
export const GET_CASE_ANALYTICS_SUCCESS = "GET_CASE_ANALYTICS_SUCCESS";
export const GET_CASE_ANALYTICS_ERROR = "GET_CASE_ANALYTICS_ERROR";

export const ADD_MALACARD_TO_CASE = "ADD_MALACARD_TO_CASE";
export const ADD_MALACARD_TO_CASE_SUCCESS = "ADD_MALACARD_TO_CASE_SUCCESS";
export const ADD_MALACARD_TO_CASE_ERROR = "ADD_MALACARD_TO_CASE_ERROR";

export const REMOVE_MALACARD_FROM_CASE = "REMOVE_MALACARD_FROM_CASE";
export const REMOVE_MALACARD_FROM_CASE_SUCCESS = "REMOVE_MALACARD_FROM_CASE_SUCCESS";
export const REMOVE_MALACARD_FROM_CASE_ERROR = "REMOVE_MALACARD_FROM_CASE_ERROR";


export * from "./auth/action";
export * from "./users/action";
export * from "./case/action";


