import { NotifyMessages, reducerSuccessAndErrortexts } from '../../Constants/notify-messages';
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    LOGOUT_USER,
    CHECK_USER_TOKEN,
    CHECK_USER_TOKEN_VALID,
    CHECK_USER_TOKEN_INVALID,
    CHECK_USER_TOKEN_ERROR,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR
} from '../Actions';

const INIT_STATE = {
    user: null,
    changedPassword: '',
    forgotPassword: '',
    // newPassword: '',
    resetPassword: '',
    loading: true,
    error: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true, error: '' };
        case LOGIN_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload, error: '' };
        case LOGIN_USER_ERROR:
            return { ...state, loading: false, user: null, error: action.payload.message };

        case CHECK_USER_TOKEN:
            return { ...state, loading: true, error: '' };
        case CHECK_USER_TOKEN_VALID:
            return { ...state, loading: false, user: action.payload, error: '' };
        case CHECK_USER_TOKEN_INVALID:
            return { ...state, loading: false, user: null, error: action.payload.message };
        case CHECK_USER_TOKEN_ERROR:
            return { ...state, loading: false, user: null, error: action.payload.message };

        case CHANGE_PASSWORD:
            return { ...state, loading: true, changedPaswordSuccess: '', error: '' };
        case CHANGE_PASSWORD_SUCCESS:
            return { ...state, loading: false, changedPaswordSuccess: reducerSuccessAndErrortexts.CHANGE_PASSWORD_SUCCESS, changedPassword: action.payload, error: '' };
        case CHANGE_PASSWORD_ERROR:
            return { ...state, loading: false, changedPaswordSuccess: '', changedPassword: '', changedPasswordError: action.payload };

        case FORGOT_PASSWORD:
            return { ...state, loading: true, forgotPassword: '', forgotPasswordError: '', error: '' };
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false, forgotPasswordError: '', forgotPassword: action.payload, error: '' };
        case FORGOT_PASSWORD_ERROR:
            return { ...state, loading: false, forgotPasswordError: action.payload.message };

        case RESET_PASSWORD:
            return { ...state, loading: true, resetPasswordError: '', error: '' };
        case RESET_PASSWORD_SUCCESS:
            return { ...state, loading: false, resetPasswordError: '', resetPassword: action.payload, error: '' };
        case RESET_PASSWORD_ERROR:
            return { ...state, loading: false, resetPassword: '', resetPasswordError: action.payload.message };
        case LOGOUT_USER:
            return { ...state, user: null, error: '' };
        default: return { ...state };
    }
}
