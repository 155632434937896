import { combineReducers } from 'redux';

import authUser from './auth/reducer';
import usersList from './users/reducer';
import casesList from './case/reducer';


const reducers = combineReducers({
    authUser,
    usersList,
    casesList
});


export default reducers;