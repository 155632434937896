import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import './Scss/styles.scss';
import 'react-quill/dist/quill.snow.css'
import Loading from "./Constants/loading";
import { Auth } from 'aws-amplify';
import './Constants/axios-middleware';
import AuthRoutes from "./Routes/auth-routes";


const App = ( {state} ) => {
  const [authenticating, setAuthenticating] = useState(true)
  useEffect(() => {
    const runEffect = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        state.user = user.getSignInUserSession().getAccessToken().payload;
        state.user.email = user.attributes.email;
      }
      catch (err) {
        console.error(err)
      }
      setAuthenticating(false);
    }
    runEffect();
  }, [])

  return (
    <>
      {!authenticating &&
        <>
          {!state && <Loading />}
          < div className="App">
            <AuthRoutes loginUser={state.user} />
          </div>
        </>
      }
    </>
  );
}


// export default App;
const mapStateToProps = ({authUser}) => {
  return { state: authUser };
};

export default connect(
  mapStateToProps,
)(App);
