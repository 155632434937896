import React, { Suspense } from 'react';
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { configureStore } from './Store/store';
import App from "./App";
import Amplify from 'aws-amplify';
import { cognito } from './config'


Amplify.configure({
    Auth:{
        mandatorySignId:true,
        region:cognito.REGION,
        userPoolId:cognito.USER_POOL_ID,
        userPoolWebClientId:cognito.APP_CLIENT_ID
    }
})
ReactDOM.render(
    <Provider store={configureStore()}>
        <Suspense fallback={<div className="loading" />}>
            <App />
        </Suspense>
    </Provider>
    , document.getElementById("root"));
