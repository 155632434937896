import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { cognito } from '../config';

// const poolData = {
//     UserPoolId: "us-west-2_SEjKq9xT8",
//     ClientId: "627mmef9pk8ftk85vsif7a1kct"
// }
const poolData = {
    UserPoolId: cognito.USER_POOL_ID,
    ClientId: cognito.APP_CLIENT_ID
}
export default new CognitoUserPool(poolData); 