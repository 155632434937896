import { Auth } from 'aws-amplify';
import UserPool from '../Constants/UserPool';
import AWS from 'aws-sdk';
import { cognito } from '../config';

// newAws //
//TODO: Replace this Jagpreet bullshit!!!

AWS.config.update({ region: 'us-west-2', 'accessKeyId': 'AKIARLWEFV2THHVQIF7D', 'secretAccessKey': 'WH/1WMtNLEcpM6a2Fw1pMF21qKeRU1fHXSvLPMDw' });
export const newAws = new AWS.CognitoIdentityServiceProvider();
//TODO: Replace Instances of newAws with congito
export const cognitoIDP = newAws;
// loadUser //

export const loadUserParams = {
    all: { Limit: 60, UserPoolId: UserPool.userPoolId },
    admin: { GroupName: "Admin", Limit: 60, UserPoolId: UserPool.userPoolId },
    teamManager: { GroupName: "TeamManager", Limit: 60, UserPoolId: UserPool.userPoolId },
    caseManager: { GroupName: "CaseManager", Limit: 60, UserPoolId: UserPool.userPoolId }
};

// headers //
export let headers;
const getAuthUser = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const cToken = user.getSignInUserSession().getIdToken().getJwtToken()
        return { 'Authorization': cToken, 'Content-Type': 'application/json' }
    }
    catch (err) {
        return err
    }
};
headers = getAuthUser().then(data => {
    headers = data
});

/**
 * Returns a list of all the groups to which a user belong
 * @param {string} username 
 * @returns A list of string with the names of the groups to which the user belongs
 */
export const getUserGroups = async (username, cb_function) => {

    const params = {
        UserPoolId: cognito.USER_POOL_ID, /* required */
        Username: username
    };
    cognitoIDP.adminListGroupsForUser(params, (err, data) =>{
        if (err) {
            cb_function(err)
            return
        } 

        let user_groups = []
        data.Groups.forEach(
            (val) => user_groups.push(val.GroupName)
        )
        cb_function(user_groups)
    });
}

export const updateUserGroups = async (username, current_groups, updated_groups) => {
}
