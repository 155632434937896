import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Loading from "../Constants/loading";


const ViewAdmin = React.lazy(() =>
  import(/* webpackChunkName: "scenes-admin" */ '../Scenes/AdminApp')
);
const ViewError = React.lazy(() =>
  import(/* webpackChunkName: "error" */ '../Scenes/error')
);
const ViewUser = React.lazy(() =>
  import(/* webpackChunkName: "user" */ '../Scenes/auth')
);

const Main = () => {
  return <Redirect to="/app" />
}


const AuthRoute = ({ loginUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (loginUser) {
          if (loginUser['cognito:groups'] && loginUser['cognito:groups'].length && loginUser['cognito:groups'].indexOf('Admin') >= 0 || loginUser['cognito:groups'].indexOf('TeamManager') >= 0) {
            return <ViewAdmin {...props} admin={loginUser} />
          }
          else {
            return <ViewUser {...props} />
          }
        }
        else {
          return <Redirect to={{ pathname: '/admin/login' }}
          />
        }
      }
      }
    />
  );
}

const AuthRoutes = ({ loginUser }) => {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Switch>
          <AuthRoute
            path="/app"
            loginUser={loginUser}
          />
          <Route
            path="/admin"
            render={props => <ViewUser {...props} />}
          />
          <Route
            path="/error"
            exact
            render={props => <ViewError {...props} />}
          />
          <Route
            path="/"
            exact
            render={props => <Main {...props} />}
          />
          <Redirect to="/error" />
        </Switch>
      </Router>
    </Suspense>
  )
}
export default AuthRoutes;
