import axios from 'axios';
import { adminConstant } from '../config';
import { headers } from '../Constants/utils';


axios.interceptors.request.use(
   (req) => {
      if (req.url.includes(adminConstant)) {
         req.headers.Authorization = headers.Authorization
      }
      return req;
   },
   (err) => {
      return Promise.reject(err);
   }
);


axios.interceptors.response.use(
   (res) => {
      if (res.status === 201) {
         console.log('Posted Successfully');
      }
      return res;
   },
   (err) => {
      return Promise.reject(err);
   }
);