
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    ADD_CASE_CARD_DETAILS,
    ADD_MALACARD_TO_CASE,
    ASSIGN_CASE_MANAGER,
    CASE_DETAILS,
    CASE_LIST,
    CHANGE_CASE_STATUS,
    CREATE_NEW_CASE,
    DELETE_CASE,
    DELETE_SYMPTOM,
    GET_CASE_ANALYTICS,
    GET_CASE_MANAGERS_LIST,
    REMOVE_CASE_MANAGER,
    REMOVE_MALACARD_FROM_CASE,
    UPDATE_CASE_DETAILS,
    UPDATE_KEY_FINDINGS,
    UPDATE_SYMPTOM
} from '../Actions';
import {
    casesListSuccess,
    casesListError,
    caseDetailsSuccess,
    caseDetailsError,
    updateCaseDetailsSuccess,
    updateCaseDetailsError,
    addCaseCardDetailsSuccess,
    addCaseCardDetailsError,
    updateSymptomSuccess,
    updateSymptomError,
    deleteSymptomSuccess,
    deleteSymptomError,
    changeCaseStatusSuccess,
    changeCaseStatusError,
    createNewCaseSuccess,
    createNewCaseError,
    assignCaseManagerSuccess,
    assignCaseManagerError,
    removeCaseManagerSuccess,
    removeCaseManagerError,
    getCaseManagersListSuccess,
    getCaseManagersListError,
    deleteCaseSuccess,
    deleteCaseError,
    updateKeyFindingsSuccess,
    updateKeyFindingsError,
    getCaseAnalytics,
    getCaseAnalyticsSuccess,
    getCaseAnalyticsError,
    addMalaCardToCaseSuccess,
    addMalaCardToCaseError,
    removeMalaCardFromCaseSuccess,
    removeMalaCardFromCaseError
} from './action';
import axios from 'axios';
import ApiConstant from '../../Constants/apiConstants';
import { adminConstant } from '../../config';
import { newAws, loadUserParams } from '../../Constants/utils';
let tma_number;

export function* watchCasesList() {
    yield takeEvery(CASE_LIST, getCasesList);
}

function* getCasesList({ payload }) {
    const { caseListParam } = payload;

    try {
        const caseCountStatus = yield call(getCasesListAsync, caseListParam);
        if (caseCountStatus.status) {
            yield put(casesListSuccess({ casesList: caseCountStatus.data.data, caseCount: caseCountStatus.data.case_count }));
        } else {
            yield put(casesListError(caseCountStatus.message));
        }
    }
    catch (err) {
        yield put(casesListError(err));

    }


}

const getCasesListAsync = async (caseListParam) => {
    try {
        const result = await axios.post(`${adminConstant}${ApiConstant.GET_CASE_LIST}`, JSON.stringify(caseListParam))
        return result;
    }
    catch (err) {
        err.message = "not Fetched"
        return err
    }
}


const getCaseDetailsAsync = async (tma_num, from) => {
    try {
        const apis = [
            axios.post(`${adminConstant}${ApiConstant.GET_CASE_DIAGNOSTICS}`, JSON.stringify({ TMA_number: tma_num })),
            axios.post(`${adminConstant}${ApiConstant.GET_CASE_HOSPITALIZATION}`, JSON.stringify({ TMA_number: tma_num })),
            axios.post(`${adminConstant}${ApiConstant.GET_CASE_IMAGINGS}`, JSON.stringify({ TMA_number: tma_num })),
            axios.post(`${adminConstant}${ApiConstant.GET_CASE_MEDICATIONS}`, JSON.stringify({ TMA_number: tma_num })),
            axios.post(`${adminConstant}${ApiConstant.GET_CASE_SURGERIES}`, JSON.stringify({ TMA_number: tma_num })),
            axios.post(`${adminConstant}${ApiConstant.GET_CASE_SYMPTOMS}`, JSON.stringify({ TMA_number: tma_num })),
            axios.post(`${adminConstant}${ApiConstant.GET_FREEFORM_INFO}`, JSON.stringify({ TMA_number: tma_num })),
            axios.post(`${adminConstant}${ApiConstant.GET_CASE}`, JSON.stringify({ TMA_number: tma_num })),
            // axios.post(`${adminConstant}${ApiConstant.TMA_CASE_ANALYTICS}`, JSON.stringify({ TMA_number: tma_num }))
        ]

        return await Promise.all(apis);
    }
    catch (e) {
        return e;
    }
}
function* getCaseDetails({ payload }) {
    const { tma_num } = payload
    try {
        const responses = yield call(getCaseDetailsAsync, tma_num);
        const failedResponse = responses.find(response => response.data.status === false);
        const { data: { timeline: diagnostoics } } = responses[0];
        const { data: { timeline: hospitalization } } = responses[1];
        const { data: { timeline: imagings } } = responses[2];
        const { data: { timeline: medications } } = responses[3];
        const { data: { timeline: surgeries } } = responses[4];
        const { data: { timeline: symptoms } } = responses[5];
        const { data: freeform } = responses[6];
        const { data: { case: caseDetails, case_manager: case_managers } } = responses[7];

        if (failedResponse) {
            console.error(failedResponse);
            yield put(caseDetailsError(failedResponse.data.message[0]));
        }
        else {
            yield put(caseDetailsSuccess({
                diagnostoics: diagnostoics,
                hospitalization: hospitalization,
                imagings: imagings,
                medications: medications,
                surgeries: surgeries,
                symptoms: symptoms,
                freeform: freeform,
                caseDetails: caseDetails,
                case_managers: case_managers
            }));
        }
    } catch (error) {
        yield put(caseDetailsError(error));

    }
}

export function* watchLoadCaseDetails() {
    yield takeEvery(CASE_DETAILS, getCaseDetails);
}

const updateCaseAsync = async ($case) => {
    try {
        const d = await axios.post(`${adminConstant}${ApiConstant.UPDATE_CASE}`, JSON.stringify($case))
        return d;
    }
    catch (e) {
        return e;
    }
}
function* updateCase({ payload }) {
    const { editedCase } = payload;
    try {
        const data = yield call(updateCaseAsync, editedCase);
        if (data.status) {
            yield put(updateCaseDetailsSuccess(data.data));
        } else {
            yield put(updateCaseDetailsError(data.error));
        }
    } catch (error) {
        yield put(updateCaseDetailsError(error));

    }
}
export function* watchUpdateCase() {
    yield takeEvery(UPDATE_CASE_DETAILS, updateCase);
}


const addCardCaseDetailsAsync = async (caseData, title) => {
    const getCaseDetailsType = (title) => {
        switch (title) {
            case "symptom":
                return ApiConstant.ADD_SYMPTOM;
            case "diagnostic":
                return ApiConstant.ADD_DIAGNOSTIC;
            case "imaging":
                return ApiConstant.ADD_IMAGING;
            case "surgery":
                return ApiConstant.ADD_SURGERY;
            case "medication":
                return ApiConstant.ADD_MEDICATION;
            case "hospitalization":
                return ApiConstant.ADD_HOSPITALIZATION;
        }
    }
    try {
        const d = await axios.post(`${adminConstant}${getCaseDetailsType(title)}`, JSON.stringify(caseData))
        return d;
    }
    catch (e) {
        return e;
    }
}
function* addCardCaseDetails({ payload }) {
    const { caseData, title } = payload;
    try {

        const data = yield call(addCardCaseDetailsAsync, caseData, title);
        if (data.status) {
            yield put(addCaseCardDetailsSuccess({ casedetail: data.data.timeline, title: title }));
        } else {
            yield put(addCaseCardDetailsError(data.error));
        }
    } catch (error) {
        yield put(addCaseCardDetailsError(error));

    }
}
export function* watchAddCaseCardDetails() {
    yield takeEvery(ADD_CASE_CARD_DETAILS, addCardCaseDetails);
}


const updateSymptomAsync = async (symptom) => {
    try {
        const d = await axios.post(`${adminConstant}${ApiConstant.EDIT_SYMPTOM}`, JSON.stringify(symptom))
        return d;
    }
    catch (e) {
        return e;
    }
}
function* updateSymptom({ payload }) {
    const { symptom } = payload;
    try {
        const data = yield call(updateSymptomAsync, symptom);
        if (data.status) {
            yield put(updateSymptomSuccess({ symptom: data.data.timeline }));
        } else {
            yield put(updateSymptomError(data.error));
        }
    } catch (error) {
        yield put(updateSymptomError(error));

    }
}
export function* watchUpdateSymptom() {
    yield takeEvery(UPDATE_SYMPTOM, updateSymptom);
}


const deleteSymptomAsync = async (symptom) => {
    try {
        const d = await axios.post(`${adminConstant}${ApiConstant.DELETE_SYMPTOM}`, JSON.stringify(symptom))
        return d;
    }
    catch (e) {
        return e;
    }
}

function* deleteSymptom({ payload }) {
    const { symptom } = payload;
    try {
        const data = yield call(deleteSymptomAsync, symptom);
        if (data.status) {
            yield put(deleteSymptomSuccess({ symptom: data.data.timeline }));
        } else {
            yield put(deleteSymptomError(data.error));
        }
    } catch (error) {
        yield put(deleteSymptomError(error));

    }
}

export function* watchDeleteSymptom() {
    yield takeEvery(DELETE_SYMPTOM, deleteSymptom);
}



const changeCaseStatusAsync = async (status) => {
    try {
        const d = await axios.post(`${adminConstant}${ApiConstant.CHANGE_CASE_STATUS}`, JSON.stringify(status))
        return d;
    }
    catch (e) {
        return e;
    }
}
function* changeCaseStatus({ payload }) {
    const { status } = payload;
    try {
        const data = yield call(changeCaseStatusAsync, status);
        if (data.status) {
            yield put(changeCaseStatusSuccess({ case: data.data }));
        } else {
            yield put(changeCaseStatusError(data.error));
        }
    } catch (error) {
        yield put(changeCaseStatusError(error));

    }
}
export function* watchChangeCaseStatus() {
    yield takeEvery(CHANGE_CASE_STATUS, changeCaseStatus);
}

const createNewCaseAsync = async (caseData) => {
    try {
        const d = await axios.post(`${adminConstant}${ApiConstant.CREATE_NEW_CASE}`, JSON.stringify(caseData))
        return d;
    }
    catch (e) {
        return e;
    }
}
function* createNewCase({ payload }) {
    const { caseData } = payload;
    try {
        const data = yield call(createNewCaseAsync, caseData);
        if (data.status && data.data !== "Case already exists for case number.") {
            yield put(createNewCaseSuccess({ case: data.data.case }));
        } else if (data.data === "Case already exists for case number.") {
            yield put(createNewCaseSuccess({ caseMessage: data.data }));
        } else {
            yield put(createNewCaseError(data.error));
        }
    } catch (error) {
        yield put(createNewCaseError(error));

    }
}
export function* watchCreateNewCase() {
    yield takeEvery(CREATE_NEW_CASE, createNewCase);
}

const getCasemanagersListAsync = async () => {
    try {
        const d = await new Promise((resolve, reject) => newAws.listUsersInGroup(loadUserParams.caseManager, (err, data) => resolve(data)))
        return d;
    }
    catch (e) {
        return e;
    }
}
function* getCasemanagersList() {
    try {
        const data = yield call(getCasemanagersListAsync);
        if (data.Users) {
            yield put(getCaseManagersListSuccess({ caseManagersList: data.Users }));
        } else {
            yield put(getCaseManagersListError(data.error));
        }
    } catch (error) {
        yield put(getCaseManagersListError(error));

    }
}
export function* watchGetCasemanagersList() {
    yield takeEvery(GET_CASE_MANAGERS_LIST, getCasemanagersList);
}


const assignCaseManagerAsync = async (caseManagers) => {
    try {
        const d = await axios.post(`${adminConstant}${ApiConstant.ASSIGN_CASE_MANAGER}`, JSON.stringify(caseManagers))
        return d;
    }
    catch (e) {
        return e;
    }
}
function* assignCaseManager({ payload }) {
    const { from, caseManagers } = payload;
    try {
        const data = yield call(assignCaseManagerAsync, caseManagers);
        if (data.status) {
            yield put(assignCaseManagerSuccess({ caseManagers: data.data.Case_manager, tmaNumber: caseManagers.TMA_number, from: from }));
        } else {
            yield put(assignCaseManagerError(data.error));
        }
    } catch (error) {
        yield put(assignCaseManagerError(error));

    }
}
export function* watchAssignCaseManager() {
    yield takeEvery(ASSIGN_CASE_MANAGER, assignCaseManager);
}



const removeCaseManagerAsync = async (removeCasemanager) => {
    try {
        const d = await axios.post(`${adminConstant}${ApiConstant.REMOVE_CASE_MANAGER}`, JSON.stringify(removeCasemanager))
        return d;
    }
    catch (e) {
        return e;
    }
}
function* removeCaseManager({ payload }) {
    const { removeCasemanager } = payload;
    try {
        const data = yield call(removeCaseManagerAsync, removeCasemanager);
        if (data.status) {
            yield put(removeCaseManagerSuccess({ username: removeCasemanager.username }));
        } else {
            yield put(removeCaseManagerError(data.error));
        }
    } catch (error) {
        yield put(removeCaseManagerError(error));

    }
}
export function* watchRemoveCaseManager() {
    yield takeEvery(REMOVE_CASE_MANAGER, removeCaseManager);
}


const deleteCaseAsync = async (deleteCase) => {
    try {
        const d = await axios.post(`${adminConstant}${ApiConstant.DELETE_CASE}`, JSON.stringify(deleteCase))
        const mongoDeleteCase = await axios.post(`${adminConstant}${ApiConstant.DELETE_CASE_MONGO}`, JSON.stringify(deleteCase))
        return d;
    }
    catch (e) {
        return e;
    }
}
function* deleteCase({ payload }) {
    const { deleteCase } = payload;
    try {
        const data = yield call(deleteCaseAsync, deleteCase);
        if (data.status) {
            yield put(deleteCaseSuccess({ deleteCaseId: deleteCase.TMA_number }));
        } else {
            yield put(deleteCaseError(data.error));
        }
    } catch (error) {
        yield put(deleteCaseError(error));

    }
}
export function* watchDeleteCase() {
    yield takeEvery(DELETE_CASE, deleteCase);
}


const updateKeyFindingsAsync = async (freeform) => {
    try {
        const d = await axios.post(`${adminConstant}${ApiConstant.UPDATE_FREE_FORM_INFO}`, JSON.stringify(freeform))
        return d;
    }
    catch (e) {
        return e;
    }
}
function* updateKeyFindings({ payload }) {
    const { freeform } = payload;
    try {
        const data = yield call(updateKeyFindingsAsync, freeform);
        if (data.status) {
            yield put(updateKeyFindingsSuccess({ description: data.data }));
        } else {
            yield put(updateKeyFindingsError(data.error));
        }
    } catch (error) {
        yield put(updateKeyFindingsError(error));

    }
}
export function* watchUpdateKeyFindings() {
    yield takeEvery(UPDATE_KEY_FINDINGS, updateKeyFindings);
}

const getCaseAnalyticsAsync = async (tma_num) => {
    try {
        const d = await axios.post(`${adminConstant}${ApiConstant.TMA_CASE_ANALYTICS}`, JSON.stringify({ TMA_number: tma_num }))
        return d;
    }
    catch (e) {
        return e;
    }
}
function* getAllCaseAnalytics({ payload }) {
    const { tma_num } = payload;
    try {
        const data = yield call(getCaseAnalyticsAsync, tma_num);
        if (data.status) {
            yield put(getCaseAnalyticsSuccess({ caseAnalytics: data.data }));
        } else {
            yield put(getCaseAnalyticsError(data.error));
        }
    } catch (error) {
        yield put(getCaseAnalyticsError(error));

    }
}
export function* watchGetCaseAnalytics() {
    yield takeEvery(GET_CASE_ANALYTICS, getAllCaseAnalytics);
}


const addMalaCardToCaseAsync = async (malacard) => {
    try {
        const d = await axios.post(`${adminConstant}${ApiConstant.TMA_ADD_MALACARD_TO_CASE}`, JSON.stringify(malacard))
        return d;
    }
    catch (e) {
        return e;
    }
}
function* addMalaCardToCase({ payload }) {
    const { malacard } = payload;

    try {
        const data = yield call(addMalaCardToCaseAsync, malacard);
        if (data.status) {
            yield put(addMalaCardToCaseSuccess({ caseAnalytics: data.data }));
        } else {
            yield put(addMalaCardToCaseError(data.error));
        }
    } catch (error) {
        yield put(addMalaCardToCaseError(error));

    }
}
export function* watchAddMalaCardToCase() {
    yield takeEvery(ADD_MALACARD_TO_CASE, addMalaCardToCase);
}


const removeMalaCardFromCaseAsync = async (Id) => {
    try {
        const d = await axios.post(`${adminConstant}${ApiConstant.TMA_REMOVE_MALACARD_FROM_CASE}`, JSON.stringify(Id))
        return d;
    }
    catch (e) {
        return e;
    }
}
function* removeMalaCardFromCase({ payload }) {
    const { _id } = payload;
    try {
        const data = yield call(removeMalaCardFromCaseAsync, _id);
        if (data.status) {
            yield put(removeMalaCardFromCaseSuccess({ caseAnalyticsId: _id.McId }));
        } else {
            yield put(removeMalaCardFromCaseError(data.error));
        }
    } catch (error) {
        yield put(removeMalaCardFromCaseError(error));

    }
}
export function* watchRemoveMalaCardFromCase() {
    yield takeEvery(REMOVE_MALACARD_FROM_CASE, removeMalaCardFromCase);
}
export default function* rootSaga() {
    yield all([
        fork(watchCasesList),
        fork(watchLoadCaseDetails),
        fork(watchUpdateCase),
        fork(watchAddCaseCardDetails),
        fork(watchUpdateSymptom),
        fork(watchDeleteSymptom),
        fork(watchChangeCaseStatus),
        fork(watchCreateNewCase),
        fork(watchAssignCaseManager),
        fork(watchRemoveCaseManager),
        fork(watchGetCasemanagersList),
        fork(watchDeleteCase),
        fork(watchUpdateKeyFindings),
        fork(watchGetCaseAnalytics),
        fork(watchAddMalaCardToCase),
        fork(watchRemoveMalaCardFromCase)




    ]);
}