const ApiConstant = {
    ONGOING_CASES_COUNT: 'tma_admin_case_manager_case_count/',
    GET_CASE_COUNT: "tma_admin_get_case_count/",
    GET_CASE_LIST: "tma_admin_get_case_list/",
    GET_CASE: "tma_admin_get_case/",
    GET_CASE_DIAGNOSTICS: "tma_admin_get_diagnostics_from_case/",
    GET_CASE_HOSPITALIZATION: "tma_admin_get_hospitalizations_from_case/",
    GET_CASE_IMAGINGS: "tma_admin_get_imagings_from_case/",
    GET_CASE_MEDICATIONS: "tma_admin_get_medications_from_case/",
    GET_CASE_SURGERIES: "tma_admin_get_surgeries_from_case/",
    GET_CASE_SYMPTOMS: "tma_admin_get_symptoms_from_case/",
    GET_FREEFORM_INFO: "tma_admin_get_case_freeform_info/",
    UPDATE_CASE: "tma_admin_edit_case/",
    ADD_SYMPTOM: "tma_admin_add_symptom/",
    ADD_DIAGNOSTIC: "tma_admin_add_diagnostics/",
    ADD_IMAGING: "tma_admin_add_imaging/",
    ADD_SURGERY: "tma_admin_add_surgery/",
    ADD_MEDICATION: "tma_admin_add_medication/",
    ADD_HOSPITALIZATION: "tma_admin_add_hospitalization/",
    EDIT_SYMPTOM: "tma_admin_edit_symptom/",
    DELETE_SYMPTOM: "tma_admin_delete_symptom/",
    UPLOAD_USER_PROFILE_IMAGE: "tma_upload_profile_picture/",
    CHANGE_CASE_STATUS: "tma_admin_update_case_status/",
    CREATE_NEW_CASE: "tma_admin_create_new_case/",
    ASSIGN_CASE_MANAGER: "tma_admin_assign_casemanager_to_case/",
    REMOVE_CASE_MANAGER: "tma_admin_remove_case_manager/",
    EDIT_FREEFORM_INFO: "tma_admin_edit_case_freeform_info/",
    DELETE_CASE: "tma_admin_delete_case/",
    UPDATE_FREE_FORM_INFO: "tma_admin_edit_case_freeform_info/",
    EDIT_CASE_MONGO: "tma_admin_edit_case_manager/",
    CHANGE_USER_STATUS_MONGO: "tma_admin_change_user_status/",
    DELETE_CASE_MONGO: "tma_admin_delete_case/",
    CREATE_CASE_MANAGER_MONGO: "tma_admin_create_case_manager/",
    TMA_SEARCH_MALACARDS: "tma_search_malacards/",
    TMA_ADD_MALACARD_TO_CASE: "tma_add_malacard_to_case/",
    TMA_CASE_ANALYTICS: "tma_case_analytics/",
    TMA_REMOVE_MALACARD_FROM_CASE: "tma_remove_malacard_from_case/"

}
export default ApiConstant;