export const NotifyMessages = {

    SYMPTOM_ADD_SUCCESS: `Symptom has been added successfully`,
    SYMPTOM_UPDATE_SUCCESS: `Symptom has been updated successfully`,
    DIAGNOSTIC_ADD_SUCCESS: `Diagnostics has been added successfully`,
    IMAGING_ADD_SUCCESS: `Imaging has been added successfully`,
    SURGERY_ADD_SUCCESS: `Surgery has been added successfully`,
    MEDICATION_ADD_SUCCESS: `Medication has been added successfully`,
    HOSPITALIZATION_ADD_SUCCESS: `Hospitalization has been added successfully`,
    ASSIGN_CASE_MANAGER_SUCCESS: `Case manager has been assigned successfully`,
    CREATE_NEW_CASE_SUCCESS: `New case has been created successfully`,
    DELETE_NEW_CASE_SUCCESS: `Case has been deleted successfully`,
    KEY_FINDINGS_SUCCESS: `Key findings has been updated successfully`,
    CHANGE_CASE_STATUS_SUCCESS: `Case status has been updated successfully`,
    REMOVE_CASE_MANAGER_SUCCESS: `Case Manager has been removed successfully`,
    DELETE_SYMPTOM_SUCCESS: `Symptom has been deleted successfully`,
    PROFILE_INFORMATION_SUCCESS: `Profile Information has been updated successfully`,
    CREATE_CASE_MANAGER_SUCCESS: `Case manager has been assigned successfully`,
    REMOVE_MALACARD_FROM_CASE_SUCCESS: `Malacard has been removed from case successfully`,
    ADD_MALACARD_TO_CASE_SUCCESS: `Malacard has been added to case successfully`,
    CHANGE_PASSWORD_SUCCESS: `Password has been Changed successfully`,
    DELETE_CASE_SUCCESS: 'Case has been deleted successfully',
    INACTIVE_BY_ADMIN: 'Your account has been set inactive by admin',
    NOT_AUTHORIZED_TO_ACCESS_ADMIN_PANEL: 'You are not authorized to access admin panel'




}

export const reducerSuccessAndErrortexts = {
    EMPTY_STRING: '',
    CREATE_NEW_CASE_SUCCESS: 'CREATE_NEW_CASE_SUCCESS',
    CREATE_NEW_CASE_ERROR: 'CREATE_NEW_CASE_ERROR',
    DELETE_CASE_SUCCESS: 'DELETE_CASE_SUCCESS',
    DELETE_CASE_ERROR: 'DELETE_CASE_ERROR',
    CHANGE_CASE_STATUS_SUCCESS: 'CHANGE_CASE_STATUS_SUCCESS',
    CHANGE_CASE_STATUS_ERROR: 'CHANGE_CASE_STATUS_ERROR',
    INVITE_CASE_MANAGER_SUCCESS: 'INVITE_CASE_MANAGER_SUCCESS',
    INVITE_CASE_MANAGER_ERROR: 'INVITE_CASE_MANAGER_SUCCESS',
    CASE_DETAILS_SUCCESS: 'CASE_DETAILS_SUCCESS',
    CASE_DETAILS_ERROR: 'CASE_DETAILS_ERROR',
    UPDATE_CASE_DETAILS_SUCCESS: 'UPDATE_CASE_DETAILS_SUCCESS',
    UPDATE_CASE_DETAILS_ERROR: 'UPDATE_CASE_DETAILS_ERROR',
    ADD_CASE_CARD_DETAILS_SUCCESS: 'ADD_CASE_CARD_DETAILS_SUCCESS',
    ADD_CASE_CARD_DETAILS_ERROR: 'ADD_CASE_CARD_DETAILS_ERROR',
    UPDATE_SYMPTOM_SUCCESS: 'UPDATE_SYMPTOM_SUCCESS',
    UPDATE_SYMPTOM_ERROR: 'UPDATE_SYMPTOM_ERROR',
    DELETE_SYMPTOM_SUCCESS: 'DELETE_SYMPTOM_SUCCESS',
    DELETE_SYMPTOM_ERROR: 'DELETE_SYMPTOM_ERROR',
    UPDATE_KEY_FINDINGS_SUCCESS: 'UPDATE_KEY_FINDINGS_SUCCESS',
    UPDATE_KEY_FINDINGS_ERROR: 'UPDATE_KEY_FINDINGS_ERROR',
    ASSIGN_CASE_MANAGER_SUCCESS: 'ASSIGN_CASE_MANAGER_SUCCESS',
    ASSIGN_CASE_MANAGER_ERROR: 'ASSIGN_CASE_MANAGER_ERROR',
    REMOVE_CASE_MANAGER_SUCCESS: 'REMOVE_CASE_MANAGER_SUCCESS',
    REMOVE_CASE_MANAGER_ERROR: 'REMOVE_CASE_MANAGER_ERROR',
    GET_CASE_MANAGERS_LIST_SUCCESS: 'GET_CASE_MANAGERS_LIST_SUCCESS',
    GET_CASE_MANAGERS_LIST_ERROR: 'GET_CASE_MANAGERS_LIST_ERROR',
    GET_CASE_ANALYTICS_SUCCESS: 'GET_CASE_ANALYTICS_SUCCESS',
    GET_CASE_ANALYTICS_ERROR: 'GET_CASE_ANALYTICS_ERROR',
    ADD_MALACARD_TO_CASE_SUCCESS: "ADD_MALACARD_TO_CASE_SUCCESS",
    ADD_MALACARD_TO_CASE_ERROR: "ADD_MALACARD_TO_CASE_ERROR",
    REMOVE_MALACARD_FROM_CASE_SUCCESS: "REMOVE_MALACARD_FROM_CASE_SUCCESS",
    REMOVE_MALACARD_FROM_CASE_ERROR: "REMOVE_MALACARD_FROM_CASE_ERROR",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",
}

export const commonText = {
    SUCCESS: 'success',
    ERROR: "error",
    ADDED: "added",
    OPEN: "Open",
    IN_PROGRESS: "In-Progress",
    COMPLETED: "Completed",
    EMPTY_EDITOR_HTML_TEXT: "<p><br></p>",
    IMAGING: `Imaging`,
    SYMPTOM: `Symptom`,
    DIAGNOSTICS: `Diagnostics`,
    MEDICATION: `Medication`,
    HOSPITALIZATION: `Hospitalization`,
    SURGERY: `Surgery`,
    DISABLED:"Disabled"
}